@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400&display=swap');

* {
    font: 1rem 'jura', sans-serif;
    line-height: 1.3;
}

html, #root {
    letter-spacing: 0.1rem;
    background-color: black;
}

.text-large {
    font-size: 4.5em;
}

.Header {
    height: 6em;
}

.Footer {
    height: 5em;
}

.divider {
    display: block;
    width: 1px;
    height: 100%;
    margin-inline: 3px;
    background-color: #474747;
    transform: skew(-20deg);
}

.Title>h1 {
    font-size: 2.75em;
}

.Title>h1:first-child {
    font-size: 3.5em;
}

img {
    object-fit: cover;
}

.NavbarSection>div {
    width: 250px;
    height: 70px;
    background-color: #474747;
    margin-top: 210px;
    transition: transform 0.2s;
}

.NavbarSection>img {
    transition: 0.2s;
}

.NavbarSection:hover>img {
    transform: scale(.95) rotate(1deg);
}

.NavbarSection:hover>div {
    transform: scale(1.05) rotate(-1deg);
}

.overview .Title {
    transition: 0.2s;
}

.overview .Title:hover {
    transform: scale(1.05);
}

.overview>p {
    max-width: 800px;
}

.logo {
    transition: 0.2s;
}

.logo:hover {
    transform: scale(1.02);
}

.arrow {
    transition: 0.2s;
}

.arrow:hover {
    transform: translateY(-5px);
}

@media only screen and (max-width: 1650px) and (min-width: 1200px) {
    .Section>div>div>img {
        width: 83%;
    }
}

@media only screen and (min-width: 1200px) {
    .NavbarSection>div {
        position: absolute;
    }

    .NavbarSection:nth-child(2n)>div {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 1200px) {
    .Navbar {
        max-width: 1000px;
    }

    .NavbarSection>div {
        margin: 10px;
    }

    .section-content {
        padding-left: 15vw;
    }

    .DetailLayout {
        max-width: 700px;
    }
}

@media only screen and (max-width: 670px) {
    .NavbarSection:nth-child(2n)>div {
        margin-left: 100px;
    }

    .NavbarSection:nth-child(2n+1)>div {
        margin-right: 100px;
    }
}